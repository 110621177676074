// 
// authentication.scss
//

// authentication pages background
body.authentication-bg {
    background: rgb(80,123,252);
    background: -moz-linear-gradient(45deg, rgba(80,123,252,1) 25%, rgba(253,29,29,1) 75%, rgba(255,154,0,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(80,123,252,1) 25%, rgba(253,29,29,1) 75%, rgba(255,154,0,1) 100%);
    background: linear-gradient(45deg, rgba(80,123,252,1) 25%, rgba(253,29,29,1) 75%, rgba(255,154,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#507bfc",endColorstr="#ff9a00",GradientType=1);
    // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 800'%3E%3Cg fill-opacity='0.22'%3E%3Ccircle fill='%23ffffff' cx='400' cy='400' r='600'/%3E%3Ccircle fill='%23eff3ff' cx='400' cy='400' r='500'/%3E%3Ccircle fill='%23cddbff' cx='400' cy='400' r='300'/%3E%3Ccircle fill='%23bbcfff' cx='400' cy='400' r='200'/%3E%3Ccircle fill='%23a8c4ff' cx='400' cy='400' r='100'/%3E%3C/g%3E%3C/svg%3E");
    // background-size: cover;
    // background-position: center;
}

.authentication-bg.enlarged,.auth-fluid-pages.enlarged {
    min-height: 100px;
}

// Logout page
.logout-icon {
    width: 140px;
}

// Authentication fluid 
.auth-fluid {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 100vh;
    flex-direction: row;
    align-items: stretch;
    background: url("../images/bg-auth.jpg") center;
    background-size: cover;

    .auth-fluid-form-box {
        max-width: 480px;
        border-radius: 0;
        z-index: 2;
        padding: 3rem 2rem;
        background-color: $white;
        position: relative;
        width: 100%;
    }

    .auth-fluid-right {
        padding: 6rem 3rem;
        flex: 1;
        position: relative;
        color: $white;
        background-color: rgba($gray-900,0.6);
    }
}

.auth-brand {
    margin-bottom: 2rem;
}

.auth-user-testimonial {
    position: absolute;
    margin: 0 auto;
    padding: 0 1.75rem;
    bottom: 3rem;
    left: 0;
    right: 0;

    p.lead {
        font-size: 1.125rem;
        margin: 0 auto 20px auto;
        max-width: 700px;
    }
}

@include media-breakpoint-up(lg) {
    .auth-brand {
        position: absolute;
        top: 3rem;
    }
}

@include media-breakpoint-down(md) {
    .auth-fluid {
        display: block;

        .auth-fluid-form-box {
            max-width: 100%;
            min-height: 100vh
        }
    
        .auth-fluid-right {
            display: none;
        }
    }
}