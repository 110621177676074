

.gradient-bg{
    background: rgb(80,123,252);
    background: -moz-linear-gradient(45deg, rgba(80,123,252,1) 25%, rgba(253,29,29,1) 75%, rgba(255,154,0,1) 100%);
    background: -webkit-linear-gradient(45deg, rgba(80,123,252,1) 25%, rgba(253,29,29,1) 75%, rgba(255,154,0,1) 100%);
    background: linear-gradient(45deg, rgba(80,123,252,1) 25%, rgba(253,29,29,1) 75%, rgba(255,154,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#507bfc",endColorstr="#ff9a00",GradientType=1);
}



// Quick Tweaks


.card {
    border-radius: 0;
    box-shadow: 0 5px 15px 0 rgba(154,161,171,.20);
    -webkit-box-shadow: 0 5px 15px 0 rgba(154,161,171,.20);
    margin-bottom: 15px;
}

.card-transparent {
    box-shadow: none;
    -webkit-box-shadow: none;
    background-color: rgba(154,161,171,.0);
}

.hand-cursor-enable {
    cursor: pointer;
}

.hand-cursor-enable:hover {
    background-color: #FAFBFE;
}

.card-test {

}

.card-tinter {
    padding: 0.5rem 1.5rem;
}

.card-tinter > .row > .col-4 {
    height: 40px;
}


.btn-light {
   -webkit-box-shadow: none;
   box-shadow: none;
}

.color-header {
    background-color: rgba(255, 255, 255, 0);
    -webkit-box-shadow: none;
   box-shadow: none;
   
}

.color-header > .card-body {
    padding: 0px 25px;
}

.border-none {
    border: none;
}

.select2-container .select2-selection--single {
    // border: none;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: rgba(255, 255, 255, 0);
}

// NAVBAR
.topnav-menu {
    // font-size: 1rem;
    text-transform: uppercase;
}



@media (min-width: 992px){
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 2rem;
        padding-left: 0rem;
    }

    .custom-bg-img {
        top:90px;
        width: 150%;
        margin-left: -30%;
      }
}